import React from 'react';
import { MotionWrap } from '../../../wrapper';
import Settings from '../../../wrapper/Settings';
import './Zmluvy.scss';

const Zmluvy = () => {
  return (
    <div className="app__header">
      <h1 className="blog">Zmluvy a faktúry</h1>  

      <div className="square-post-container1">
        <p className='text'>Prehlásenie o prístupnosti</p>   
        <article>
          <p>
            Webové sídlo Základnej umeleckej školy <a href="https://www.zusirkutska.sk/" target="_blank" rel="noopener noreferrer">https://www.zusirkutska.sk/</a> sa v čo najväčšej miere snaží o dodržiavanie platnej legislatívy v oblasti prístupnosti webových stránok, a to najmä zákona 275/2006 Z. z. o informačných systémoch verejnej správy v znení neskorších predpisov a platného výnosu Ministerstva financií SR o štandardoch informačných systémov verejnej správy.
          </p>
          <p>
            Základná umelecká škola, Irkútska 1, Košice si je vedomá nedodržania štandardov v niektorých častiach stránky, kde zverejňuje dokumenty, doručené Základnej umeleckej škole, Irkútska 1, Košice výlučne klasickou poštou. Ide o dokumenty, ktoré Základná umelecká škola, Irkútska 1, Košice netvorí ani nepripomienkuje a nemá k dispozícii zdroj príslušných textov v elektronickej forme, preto zverejňuje informáciu v jedinej dostupnej forme ako naskenované texty.
          </p>
        </article>
      </div>

      <div className="square-post-container1">
        <p className='text'>Zmluvy a faktúry</p>
        <article>
           <p>
            Zoznam zverejnených zmlúv nájdete na 
            <a href="https://www.crz.gov.sk/6277851-sk/zakladna-umelecka-skola-irkutska-1-kosice/" target="_blank" rel="noopener noreferrer"> Klikni SEM</a>.
          </p>
          <p>
            Prehľad faktúr nájdete na 
            <a href="https://zmluvy.egov.sk/egov/contracts/place:393" target="_blank" rel="noopener noreferrer"> Klikni SEM</a>.
          </p>
         


        </article>
      </div>
    </div>
  );
};

export default Settings(MotionWrap(Zmluvy, 'app__skills'), 'skills', 'app__whitebg');




