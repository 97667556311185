import React, { useState } from 'react';
import './Navbar.scss';
import { HiMenu, HiX } from 'react-icons/hi';
import { BiHomeHeart } from 'react-icons/bi';
import { motion, AnimatePresence } from 'framer-motion';
import logoskola from '../../assets/logoskola.png';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [subMenuToggle, setSubMenuToggle] = useState(false);
  const [secondSubMenuToggle, setSecondSubMenuToggle] = useState(false);
  const [thirdSubMenuToggle, setThirdSubMenuToggle] = useState(false);
  const [fourthSubMenuToggle, setFourthSubMenuToggle] = useState(false);
  const [fifthSubMenuToggle, setFifthSubMenuToggle] = useState(false);
  const [sixthSubMenuToggle, setSixthSubMenuToggle] = useState(false);
  const [sevenSubMenuToggle, setSeventhSubMenuToggle] = useState(false);
  const [kontaktySubMenuToggle, setKontaktySubMenuToggle] = useState(false); // State for Kontakty submenu
  const [singleClickTimer, setSingleClickTimer] = useState(null);

  const handleMouseLeave = () => {
    setToggle(false);
    setSubMenuToggle(false);
    setSecondSubMenuToggle(false);
    setThirdSubMenuToggle(false);
    setFourthSubMenuToggle(false);
    setFifthSubMenuToggle(false);
    setKontaktySubMenuToggle(false);
    setSeventhSubMenuToggle(false);
  };

  const handleCompanyClick = () => {
    if (singleClickTimer) {
      clearTimeout(singleClickTimer);
      setSingleClickTimer(null);
    }

    const timer = setTimeout(() => {
      const otazkySection = document.getElementById('Tvorba');
      if (otazkySection) {
        otazkySection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);

    setSingleClickTimer(timer);
  };

  const handleCompanyClick2 = () => {
    if (singleClickTimer) {
      clearTimeout(singleClickTimer);
      setSingleClickTimer(null);
    }

    const timer = setTimeout(() => {
      const otazkySection = document.getElementById('work');
      if (otazkySection) {
        otazkySection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);

    setSingleClickTimer(timer);
  };

  const handleSubmenuClick = () => {
    setFifthSubMenuToggle(!fifthSubMenuToggle);
  };

  const handleNašeAktivityClick = () => {
    handleCompanyClick2(); // Scroll to 'work' section
    handleSubmenuClick(); // Toggle submenu
  };

  return (
    <nav className='app__navbar'>
      <div className='app__navbar-menu'>
        <HiMenu className='mobile-view2' onClick={() => setToggle(true)} style={{ fill: 'black' }} />
        
        <AnimatePresence>
          {toggle && (
            <motion.div
              key='menu'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, ease: 'easeOut' }}
              onMouseLeave={handleMouseLeave}
            >
              <HiX onClick={() => setToggle(false)} />
              <ul>
                {[
                  'O škole',
                  <Link to="/Second-Page">AKTUALITY</Link>,
                  'BIENÁLE PORTRÉTU KOŠICE',
                  'Dokumenty',
                  'Poplatky',
                  <Link to="https://www.izus.cz/prihlaska_do_zus/nova/?zus=766" target="_blank" className="submenu">PRIHLÁŠKA DO ZUŠ</Link>,
                  <Link to="/prijimacie-konanie">PRIJÍMACIE KONANIE</Link>,
                  <a href="https://www.izus.cz/" target="_blank" rel="noopener noreferrer"> <p>IZUŠ</p> </a>,
                  <Link to="/dan">DARUJTE 2%</Link>,
                  <Link to="/kontakty">KONTAKT</Link>,
                ].map((item) => (
                  <li key={item}>
                    <a
                      className='submenu'
                      onClick={() => {
                        setToggle(true);
                        if (item === 'O škole') {
                          setSubMenuToggle(!subMenuToggle);
                        }
                        if (item === 'BIENÁLE PORTRÉTU KOŠICE') {
                          setSixthSubMenuToggle(!sixthSubMenuToggle);
                        }
                        if (item === '2025') {
                          setSeventhSubMenuToggle(!sevenSubMenuToggle);
                        }
                        if (item === 'Zamestnanci') {
                          setSecondSubMenuToggle(!secondSubMenuToggle);
                        }
                        if (item === 'Dokumenty') {
                          setThirdSubMenuToggle(!thirdSubMenuToggle);
                        }
                        if (item === 'Poplatky') {
                          setFourthSubMenuToggle(!fourthSubMenuToggle);
                        }
                        if (item === 'Tvorba') {
                          window.location.href = '/#Tvorba';
                        }
                        if (item === 'Kontakty') {
                          setKontaktySubMenuToggle(!kontaktySubMenuToggle); // Toggle Kontakty submenu
                        }
                      }}
                    >
                      {item}
                    </a>

                    {item === 'O škole' && subMenuToggle && (
                      <motion.ul
                        className='submenu'
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, ease: 'easeOut' }}
                      >
                        <li> <Link to="/#Tvorba" onClick={handleCompanyClick}>Tvorba</Link></li>
                        <li className='submenu-item'> <Link to="/profil-skoly">Profil školy</Link></li>
                        <li key="našeAktivity">
                          <Link to="/#work" onClick={handleNašeAktivityClick}>Naše aktivity</Link>
                          {fifthSubMenuToggle && (
                            <motion.ul
                              className='sub-submenu'
                              initial={{ opacity: 0, y: -20 }}
                              animate={{ opacity: 1, y: 0 }}
                              transition={{ duration: 0.3, ease: 'easeOut' }}
                              onClick={handleSubmenuClick}
                            >
                              <li className='sub-submenu-item'>
                                <a href="https://noonsro.pixieset.com/plenervmadarsku-fuzer/" target="_blank" rel="noopener noreferrer">Plenér v Maďarsku Fúzer</a>
                              </li>
                              <li className='sub-submenu-item'>
                                <a href="https://noonsro.pixieset.com/dedet/" target="_blank" rel="noopener noreferrer">Deň detí Irkutská 2023 </a>
                              </li>
                              <li className='sub-submenu-item'>
                                <a href="https://noonsro.pixieset.com/bienleportrtukoice2023/" target="_blank" rel="noopener noreferrer">Deň detí Irkutská 2023 </a>
                              </li>
                            </motion.ul>
                          )}
                        </li>
                        <li className='submenu-item'>
                          <p
                            className='sub-submenu-item text-bold'
                            onClick={() => setSecondSubMenuToggle(!secondSubMenuToggle)}
                          >
                            ZAMESTNANCI
                          </p>
                          {secondSubMenuToggle && (
                            <motion.ul
                              className='sub-submenu'
                              initial={{ opacity: 0, y: -20 }}
                              animate={{ opacity: 1, y: 0 }}
                              transition={{ duration: 0.3, ease: 'easeOut' }}
                            >
                              <li className='sub-submenu-item'> <Link to="/pedagogovia" >Pedagogickí</Link></li>
                              <li className='sub-submenu-item'> <Link to="/nepedagogovia" >Nepedagogickí</Link></li>
                            </motion.ul>
                          )}
                        </li>
                      </motion.ul>
                    )}

                    {item === 'BIENÁLE PORTRÉTU KOŠICE' && sixthSubMenuToggle && (
                      <motion.ul
                        className='submenu'
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, ease: 'easeOut' }}
                      >
                        <li className="submenu-item">
                          <a href="https://white-geneva-29.tiiny.site/" target="_blank" rel="noopener noreferrer">2021</a>
                        </li>
                        <li className="submenu-item">
                          <a href="https://emerald-florencia-56.tiiny.site" target="_blank" rel="noopener noreferrer">2023</a>
                        </li>
                        <li className='submenu-item'>
                          <p
                            className='sub-submenu-item text-bold'
                            onClick={() => setSeventhSubMenuToggle(!sevenSubMenuToggle)}
                          >
                            2025
                          </p>
                          {sevenSubMenuToggle && (
                            <motion.ul
                              className='sub-submenu'
                              initial={{ opacity: 0, y: -20 }}
                              animate={{ opacity: 1, y: 0 }}
                              transition={{ duration: 0.3, ease: 'easeOut' }}
                            >
                              <li className='sub-submenu-item'> <Link to="/" >Plagát</Link></li>
                              <li className='sub-submenu-item'> <Link to="/post/propozicie-bienale-2025" >Propozície 2025</Link></li>
                              <li className='sub-submenu-item'> <Link to="https://prihlsaska.tiiny.site/" >Štítok</Link></li>
                              <li className='sub-submenu-item'> <Link to="https://e.pcloud.link/publink/show?code=XZ9nEKZNmwov1tfvs7Uusnu453UdheRzgO7" >GDPR</Link></li>
                              <li className='sub-submenu-item'> <Link to="/" >Poster</Link></li>
                              <li className='sub-submenu-item'> <Link to="/post/propositions-en" >Propositions 2025</Link></li>
                              <li className='sub-submenu-item'> <Link to="https://emerald-faina-84.tiiny.site/" >Label </Link></li>
                              <li className='sub-submenu-item'> <Link to="https://e.pcloud.link/publink/show?code=XZhMEKZSBUpIfbGYI0JVvLKX1gEyuSJMjDy" >GDPR</Link></li>
                            </motion.ul>
                          )}
                        </li>
                      </motion.ul>
                    )}

                    {item === 'Dokumenty' && thirdSubMenuToggle && (
                      <motion.ul
                        className='submenu'
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, ease: 'easeOut' }}
                      >
                        <li className='submenu-item'>
                          <Link to='/spravy'>Správy</Link>
                        </li>
                        <li className='submenu-item'>
                          <Link to='/zmluvy'>Zmluvy a objednávky</Link>
                        </li>
                        <li className='submenu-item'>
                          <Link to='/faktury'>Faktury</Link>
                        </li>
                        <li className='submenu-item'>
                              <Link to='https://e.pcloud.link/publink/show?code=XZg39KZsSE3SiWI1ih3AP2bzQeoGXXqy6Vy' target="_blank">Kolektívna zmluva 2025</Link>
                       </li>
                         <li className='submenu-item'><Link to='https://e.pcloud.link/publink/show?code=XZC39KZUcOb1Vusev8EBKHn6o0tTfAqhW6y' target="_blank">Dodatok č.1 Kolektívna zmluva 2025</Link></li>
                        <li className='submenu-item'>
                          <a href='https://e.pcloud.link/publink/show?code=XZvT2nZ5iHaqY6YyvVnpVIuVFbQo7FyHcO7' target='_blank' rel='noopener noreferrer' className='submenu'>
                            ŠkVP 
                          </a>
                        </li>
                        <li className='submenu-item'>
                          <a href='https://e.pcloud.link/publink/show?code=XZlMunZsFcWILM1uBp3IGcKbWUvMQgH6vJ7' target='_blank' rel='noopener noreferrer' className='submenu'>
                            Organizačný poriadok
                          </a>
                        </li>
                        <li className='submenu-item'>
                          <Link to='/GDPR'>GDPR</Link>
                        </li>
                        <li className='submenu-item'>
                          <Link to='/ochrana-oznamovatelov'>OCHRANA OZNAMOVATEĽOV</Link>
                        </li>
                      </motion.ul>
                    )}

                    {item === 'Poplatky' && fourthSubMenuToggle && (
                      <motion.ul
                        className='submenu'
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, ease: 'easeOut' }}
                      >
                        <li className='submenu-item'>
                          <Link to='/skolne'>Školné</Link>
                        </li>
                        <li className='submenu-item'>
                          <Link to='/rodicovske'>Rodičovské</Link>
                        </li>
                      </motion.ul>
                    )}

                    {item === 'Kontakty' && kontaktySubMenuToggle && (
                      <motion.ul
                        className='submenu'
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, ease: 'easeOut' }}
                      >
                        <li className='submenu-item'>
                          <a href="https://www.kosice.sk/sk" target="_blank" rel="noopener noreferrer" className='submenu'>Transparentná škola</a>
                        </li>
                      </motion.ul>
                    )}
                  </li>
                ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className='mt-4'>
        <a href="/" style={{ textDecoration: 'none' }}>
          <img src={logoskola} alt="Logo" className="logo" />
        </a>
      </div>
      <div className='app__navbar-menu'>
        <BiHomeHeart className='mobile-view' style={{ fill: 'black' }} onClick={() => { window.location.href = '/'; }} />
      </div>
    </nav>
  );
};

export default Navbar;


